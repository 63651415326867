import React, { useState } from "react";
import { graphql } from "gatsby"

// components
import Asset from '../components/asset/Asset';
import Layout from "../components/layout/Layout";
import Seo from "../components/seo/SEO";

// svgs 
import DownArrow from '../svgs/down_arrow.svg';

export const query = graphql`
  query ($id: [Craft_QueryArgument]) {
    craft {
      entries(id: $id) {
        title
        uri
        ... on Craft_series_series_Entry {
          pageSeo {
            description
            keywords {
              keyword
            }
            advanced {
                robots
                canonical
            }
            social {
              facebook {
                title
                description
                image {
                  ... on Craft_series_Asset {
                    url
                  }
                }
              }
              twitter {
                title
                description
                image {
                  ... on Craft_series_Asset {
                    url
                  }
                }
              }
            }
            title
          }
          linksMatrix {
            ... on Craft_linksMatrix_linkBlock_BlockType {
              linkTitle
              linkUrl
            }
          }
          socialLinksMatrix {
            ... on Craft_socialLinksMatrix_linkBlock_BlockType {
              linkTitle
              linkUrl
            }
          }
          contentMatrix {
            ... on Craft_contentMatrix_contentBlock_BlockType {
              typeHandle
              contentTitle
              richText
              releaseYear
              directedBy
              writtenBy
              starring
              studio
              featuredImage {
                ... on Craft_series_Asset {
                  title
                  url
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
              posterImage {
                ... on Craft_images_Asset {
                  title
                  url
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const ShowPage = ({data}) => {
  const [dropdown, setDropdown] = useState(false);

  const toggleDropdown = () => {
    if (dropdown) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  }

  const entry = data.craft.entries[0];
  const content = entry.contentMatrix[0];

  const seo = entry.pageSeo;

  let image = null;
  image = content.featuredImage ? content.featuredImage[0] : null;

  let poster = null;
  poster = content.posterImage ? content.posterImage[0] : null;

  let watch = null;
  watch = entry.linksMatrix.length > 0 ? entry.linksMatrix : null;

  const watchSelect = <div className={`flex justify-between dropdown-button`} aria-label={`button`} role={`button`} tabIndex={0} onClick={toggleDropdown} onKeyDown={toggleDropdown}>
    <h3 className={`m-0`}>Watch Now</h3>
    <div className={`dropdown-button__arrow ${dropdown ? 'dropdown-button__arrow--rotate' : null}`}>
      <DownArrow />
    </div>
  </div>;

  let dropdownOptions = null;
  dropdownOptions = watch ? watch.map((item, i) => {
    return (
      <a key={i} className={`dropdown-item`} href={item.linkUrl} target="_blank" rel="noreferrer">
        <h2>{item.linkTitle}</h2>
      </a>
    )
  }) : null;

  let socials = null;
  socials = entry.socialLinksMatrix.length > 0 ? entry.socialLinksMatrix : null;

  const socialList = socials ? socials.map((item, i) => {
    return (
      <a key={i} className={`socials__item flex align-center justify-center center-text`} href={item.linkUrl} target="_blank" rel="noreferrer">
        <h4 className={`bold`}>{item.linkTitle}</h4>
      </a>
    )
  }) : null;

  let keywords = [];
  if (seo.keywords) {
    seo.keywords.forEach((keyword) => {
      keywords.push(keyword.keyword);
    })
  }

  return (
    <Layout>
      <Seo 
        title={entry.title} 
        canonical={seo.advanced.canonical}
        facebookImage={seo.social.facebook.image?.url}
        robots={seo.advanced.robots}
        twitterImage={seo.social.twitter.image?.url}
        description={seo.description ? seo.description : content.richText.replace(/<[^>]*>?/gm, '')}
        image={image.url}
        keywords={keywords}
      />

      <div className={`detail`}>
        <div className={`detail__top grid grid--2-1`}>
          {image ? <Asset data={image.imageFile} title={image.title} /> : null}

          <div className={`detail__inner-top relative`}>
            <h1 className={`tablet-only`}>{entry.title}</h1>

            {watch ? watchSelect : null}

            {dropdown ?
              <div className={`dropdown-container`}>{dropdownOptions}</div>
              : null
            }

            {socials ? <div className={`flex justify-between align-center socials`}>{socialList}</div> : null}
          </div>
        </div>

        <div className={`detail__bottom grid grid--2-1 grid--gap-4`}>
          <div className={`detail__details`}>
            <h1 className={`no-tablet`}>{entry.title}</h1>
            <div className={`detail__copy ${poster ? 'm-24' : ''}`} dangerouslySetInnerHTML={{ __html: content.richText }} />
            <div className={`detail__poster`}>{poster ? <Asset data={poster.imageFile} title={poster.title} /> : null}</div>
          </div>

          <div className={`detail__list`}>
            <div className={`detail__list-item`}>
              <h4 className={`bold uppercase`}>Year</h4>
              <p>{content.releaseYear}</p>
            </div>

            <div className={`detail__list-item`}>
              <h4 className={`bold uppercase`}>Created By</h4>
              <p>{content.directedBy}</p>
            </div>

            {content.starring ? 
              <div className={`detail__list-item`}>
                <h4 className={`bold uppercase`}>Starring</h4>
                <p>{content.starring}</p>
              </div>
            : null}

            {content.studio ?
              <div className={`detail__list-item`}>
                <h4 className={`bold uppercase`}>Studio</h4>
                <p>{content.studio}</p>
              </div>
              : null}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ShowPage
